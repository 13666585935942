export default {
  namespaced: true,
  state: {
    account: false,
    meeting: false,
    billing: false,
    attendee: false,
    addGroup: false,
    room: false,
    timezone: false,
    repeating: false,
    deleteEventConfirmation: false,
    deleteRoomConfirmation: false,
    deleteAttendeeConfirmation: false,
    firstTimeWizard: false,
    updateAttendeeConfirmation: false,
    updateRoomsConfirmation: false,
    rateExperience: true,
    saveEventConfirmation: false,
    deleteEventSimpleConfirmation: false,
    terms: false,
    appInfo: false,
  },
  mutations: {
    deleteEventSimpleConfirmation(state, value) {
      state.deleteEventSimpleConfirmation = value;
    },
    saveEventConfirmation(state, value) {
      state.saveEventConfirmation = value;
    },
    rateExperience(state, value) {
      state.rateExperience = value;
    },
    appInfo(state, value) {
      state.appInfo = value;
    },
    updateRoomsConfirmation(state, value) {
      state.updateRoomsConfirmation = value;
    },
    updateAttendeeConfirmation(state, value) {
      state.updateAttendeeConfirmation = value;
    },
    deleteEventConfirmationModal(state, value) {
      state.deleteEventConfirmation = value;
    },
    deleteRoomConfirmationModal(state, value) {
      state.deleteRoomConfirmation = value;
    },
    deleteAttendeeConfirmationModal(state, value) {
      state.deleteAttendeeConfirmation = value;
    },
    firstTimeWizardModal(state, value) {
      state.firstTimeWizard = value;
    },
    repeatingModal(state, value) {
      state.repeating = value;
    },
    timezoneModal(state, value) {
      state.timezone = value;
    },
    roomModal(state, value) {
      state.room = value;
    },
    terms(state, value) {
      state.terms = value;
    },
    attendeeModal(state, value) {
      state.attendee = value;
    },
    billingModal(state, value) {
      state.billing = value;
    },
    accountModal(state, value) {
      state.account = value;
    },
    meetingModal(state, value) {
      state.meeting = value;
    },
    addGroup(state, value) {
      state.addGroup = value;
    },
  },
};
