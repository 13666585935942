<template>
  <section class="relative">
    <div class="d-flex w100" ref="button">
      <div class="w100">
        <input
          maxlength="0"
          @keydown.prevent.delete="(e) => e.preventDefault()"
          @keydown.prevent.enter="toggle"
          @keydown.prevent.space="toggle"
          @keydown.prevent.esc="close"
          type="button"
          @click="toggle"
          data-lpignore="true"
          :style="inputStyling"
          v-model="selected"
          class="capital w100"
          :class="{ noedit: noedit }"
        />
        <div class="line" ref="line"></div>
      </div>
      <button
        class="box center"
        type="button"
        v-if="!noedit"
        @click="toggle"
        tabindex="-1"
        :style="boxStyling"
      >
        <s-icon width="20" height="20" color="grey">chevron-down</s-icon>
      </button>
    </div>
    <transition name="select">
      <section
        v-hotkey="{ esc: close }"
        v-if="show"
        class="dropdown"
        :style="dropdown"
        ref="dropdown"
        v-outside="toggle"
      >
        <button
          ref="button"
          class="element"
          type="button"
          :style="element"
          @keydown.prevent.shift.tab="up($event)"
          @keydown.prevent.tab.exact="down($event)"
          @keydown.prevent.down="down($event)"
          @keydown.prevent.up="up($event)"
          @click="clickHandle(item)"
          :class="{ selected: item.selected }"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="d-flex align-center ml-10">
            <div
              v-if="item.color"
              class="room_box mr-10"
              :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
            ></div>
            <div class="">{{ item.display }}</div>
          </div>
          <s-icon
            :key="key"
            v-show="item.selected"
            class="mx-10"
            height="16"
            color="white"
            >check</s-icon
          >
        </button>
      </section>
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      selected: "",
      key: 0,
      multiSelect: [],
    };
  },
  props: ["height", "value", "noedit", "items", "multi"],
  methods: {
    toggle() {
      this.$emit("focused");
      if (this.noedit) return;
      this.show = !this.show;
      if (this.show) this.$refs.line.style.width = "100%";
      if (!this.show) this.$refs.line.style.width = "0%";
    },
    close() {
      this.show = false;
      this.$refs.line.style.width = "0%";
      // this.$refs.button.focus();
    },
    clickHandle(e) {
      if (!this.multiple) this.items.forEach(this.unselect);
      if (!e.selected) {
        e.selected = true;
        this.multiSelect.push(e);

        this.key += 1;
      } else {
        this.key += 1;

        if (this.multiple) {
          e.selected = false;
          let index = this.multiSelect.findIndex((el) => el.value == e.value);
          this.multiSelect.splice(index, 1);
        }
      }
      if (this.multiple) this.$emit("multiSelect", this.multiSelect);
      this.selected = e.display;
      this.$emit("input", e);
      if (this.multiple) return;
      this.$emit("blur");
      this.close();
    },
    unselect(val) {
      return (val.selected = false);
    },
    filter(val) {
      return val.selected == true;
    },
    updateSelected() {
      if (this.multiple) return;
      this.selected = this.items.filter(this.filter)[0];
      if (!this.selected) return;
      this.selected = this.selected.display;
    },
    down(el) {
      el = el.target;
      if (el.nextSibling) return el.nextSibling.focus();
      el.parentElement.firstChild.focus();
    },
    up(el) {
      el = el.target;
      if (el.previousSibling) return el.previousSibling.focus();
      el.parentElement.lastChild.focus();
    },
    focusSelectNode() {
      let selected = this.items.findIndex(this.filter);
      this.$refs.dropdown?.childNodes[selected]?.focus();
    },
  },
  computed: {
    inputStyling: function () {
      let ob = {};
      if (this.noedit) ob.backgroundColor = "#00000000";
      if (this.height) ob.height = this.height + "px";
      return ob;
    },
    element: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      return ob;
    },
    boxStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (this.height) ob.minWidth = this.height + "px";
      return ob;
    },
    dropdown: function () {
      let ob = {};
      if (this.height) ob.top = 1 + this.height + "px";
      return ob;
    },
    multiple: function () {
      let con1 = this.multi == true;
      let con2 = this.multi !== "";
      let con3 = typeof this.multi == "undefined";
      return con1 || con2 || con3 ? false : true;
    },

    ...mapState("user", ["lang"]),
  },
  watch: {
    async show() {
      if (!this.show) return;
      await this.$wait();
      this.focusSelectNode();
    },
    lang() {
      this.updateSelected();
    },
    items() {
      if (!this.items) return;
      this.updateSelected();
    },
  },
  mounted() {
    this.popupItem = this.$refs.button;
    if (this.multiple) {
      this.selected = "Choose";
      for (let x of this.items) {
        if (x.selected) {
          this.multiSelect.push(x);
        }
      }
    }

    if (!this.items || this.multiple) return;
    let index = this.items.findIndex((el) => el?.selected == true);
    if (index == -1) index = 0;
    if (this.items.length == 0) return;
    this.items[index].selected = true;
    this.updateSelected();
    this.$emit("input", this.items[index]);
  },
};
</script>

<style scoped lang='less'>
.dropdown {
  position: absolute;
  background-color: #151b23;
  z-index: 1;
  font-size: 14px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  min-width: 100%;
  max-height: 250px;
  overflow: overlay;
  top: 31px;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  caret-color: transparent;
  user-select: none;
  cursor: pointer;
  text-align: start;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.element {
  height: 30px;
  width: 133px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  color: rgb(160, 160, 160);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.element:hover {
  background-color: #00000054;
  color: rgb(255, 255, 255);
}

.item:focus {
  background-color: #000000a3;
  color: rgb(255, 255, 255);
}

.box {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

input::selection {
  background: none;
}

.noedit {
  cursor: default;
}

.noedit:focus {
  color: rgba(255, 255, 255, 0.75);
}

.room_box {
  height: 10px;
  width: 10px;
}

@media only screen and (max-width: 576px) {
  .dropdown {
    left: 0;
    right: 0;
    top: 41px;
  }
}
</style>