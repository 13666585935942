export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    push(state, response) {
      if (response.error == "authentication failure") return;
      state.notifications.push(response);
    },
    raw(state, response) {
      if (response.error == "authentication failure") return;
      if (response.error) {
        response = { error: response.error };
        state.notifications.push(response);
      }
      if (response.status == "ok") {
        response = { success: "Success" };
        state.notifications.push(response);
      }
    },
    clearData(state, response) {
      state.notifications = [];
    },
  },
};
