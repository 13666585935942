import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>  import(`../views/Home.vue`),
  },
  {
    path: "/terms-of-service",
    name: "TOS",
    component: () => import("../views/TOS.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/data-privacy-addendum",
    name: "PrivacyAddendum",
    component: () => import("../views/PrivacyAddendum.vue"),
  },
  {
    path: "/end-user-license-agreement",
    name: "EULA",
    component: () => import("../views/EULA.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views/Home/Support.vue"),
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    component: () => import("../views/Enterprise.vue"),
  },
  {
    path: "/whitepaper",
    name: "Whitepaper",
    component: () => import("../views/Whitepapers.vue"),
  },
  {
    path: "/pdf/:file",
    name: "PDF",
    component: () => import("../views/PDF.vue"),
  },
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let login = from.name == "Login";
  let register = from.name == "Register";
  let dashboard = to.name == "Dashboard";
  let user = store.state.user.email;
  let logged = to.name == "Login" || to.name == "Register";
  store.commit("user/routerHistory", to.name);

  if ((login || register) && dashboard) {
    let counter = 0;
    let intervalID = setInterval(() => {
      if (store.state.user.email) {
        clearInterval(intervalID);
        return next();
      }
      if (++counter > 1000) clearInterval(intervalID);
    }, 10);
    return;
  }

  if (user && logged) return next({ name: "Dashboard" });
  if (!to.meta.requiresAuth) return next();
  if (!store.state.user.email) return next({ name: "Login" });
  if (store.state.user.email) return next();
});

export default router;
