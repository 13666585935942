import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth"
import user from "./user";
import modals from "./modals";
import toolbars from "./toolbars";
import notifications from "./notifications";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    modals,
    toolbars,
    notifications,
    auth
  },
  plugins: [
    createPersistedState({
      paths: [
        "user",
        "modals",
        "toolbars",
        "notifications",
        "auth"
      ],
    }),
  ],
});
