export const auth = function(response) {
  return true;
};

const header = { "Content-type": "application/json; charset=UTF-8" };

export const get = async function(url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  return response;
};

export const getLocation = async function() {
  const url = process.env.VUE_APP_LOCATION_SERVICE + "/api/location";
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  return response;
};


export const post = async function(url, data) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  return response;
};

export const del = async function(url) {
  url = process.env.VUE_APP_URL + url;
  let response = await fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: header,
  });
  response = await response.json();
  return response;
};
