<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {
    initGuestAccount() {
      this.$store.dispatch("user/getLangGuest");
    },
  },
  computed: {
    ...mapState("user", ["email"]),
  },
  mounted() {
    this.initGuestAccount();
    this.$store.dispatch("user/getLocation");
  },
};
</script>

