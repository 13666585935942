import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMq from "vue-mq";
import VueHotkey from "v-hotkey";
import VTooltip from "v-tooltip";
import i18n from "./assets/i18n";
import VueClipboard from "vue-clipboard2";
import VueFocusLoop from "@vue-a11y/focus-loop";
import clickOutside from "./assets/directives";
import resize from "vue-element-resize-detector";
import VueScreen from 'vue-screen';
import ClickOutside from "vue-click-outside";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import _ from 'lodash';

import Icon from "@/components/Atoms/Icon.vue";
import Radio from "@/components/Atoms/Radio.vue";
import Button from "@/components/Atoms/Button.vue";
import Modal from "@/components/Modals/Assets/Modal.vue"
import Dropdown from "@/components/Atoms/Dropdown.vue";
import Calendar from "@/components/Atoms/Calendar.vue";
import Checkbox from "@/components/Atoms/Checkbox.vue";
import Timezone from "@/components/Atoms/Timezone.vue";
import TextArea from "@/components/Atoms/TextArea.vue";
import Animation from "@/components/Atoms/AnimatedLogo";
import TextField from "@/components/Atoms/TextField.vue";
import DatePicker from "@/components/Atoms/DatePicker.vue";
import TimePicker from "@/components/Atoms/TimePicker.vue";
import ColorPicker from "@/components/Atoms/ColorPicker.vue";

Vue.component("s-icon", Icon);
Vue.component("s-btn", Button);
Vue.component("s-radio", Radio);
Vue.component("s-logo", Animation);
Vue.component("s-dropdown", Dropdown);
Vue.component("s-calandar", Calendar);
Vue.component("s-checkbox", Checkbox);
Vue.component("s-timezone", Timezone);
Vue.component("s-text-area", TextArea);
Vue.component("s-text-field", TextField);
Vue.component("s-date-picker", DatePicker);
Vue.component("s-time-picker", TimePicker);
Vue.component("s-color-picker", ColorPicker);
Vue.component('pulse-loader', PulseLoader);
Vue.component('Modal', Modal);
Vue.directive("click-outside", clickOutside);
Vue.directive("outside", ClickOutside);

import "@/assets/global.less";
import "@/assets/helper.less";
import "@/assets/spacing.less";
import "@/assets/animations.less";

Vue.prototype.$wait = async (time) => {
  if (!time) time = 0;
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

let br = { breakpoints: { sm: 576, md: 768, lg: 1200 } };

Vue.config.productionTip = false;

Vue.use(VueMq, br);
Vue.use(VueHotkey);
Vue.use(VTooltip);
Vue.use(VueClipboard);
Vue.use(VueFocusLoop);
Vue.use(resize);
Vue.use(VueScreen, 'bootstrap'); 

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
