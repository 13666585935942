<template>
  <div ref="datePickerContainer" class="v">
    <div class="datepicker noselect">
      <div class="d-flex space-between capital">
        <div class="pl-10">
          {{ date.locale(lang).format("MMM") }} {{ date.format("YYYY") }}
        </div>
        <section class="center">
          <button class="mr-15 center iconFilter" @click="decrement">
            <s-icon color="white">chevron-left</s-icon>
          </button>
          <button class="center iconFilter" @click="increment">
            <s-icon color="white">chevron-right</s-icon>
          </button> 
        </section>
      </div>
      <div class="headings capital">
        <div v-for="item in daysOfWeek" :key="item.idx">
          {{ item }}
        </div>
      </div>
      <div class="grid-container">
        <div
          v-for="item in monthRange"
          :key="item.idx"
          class="center grid-item"
        >
          <button
            v-if="item.month() + 1 == date.format('M')"
            class="opacity-60 today-oval center oval"
            @click="selectDate(item)"
            :class="{
              green: today == item.format('DD/MMM/YYYY'),
              selected:
                absDate.format('MMM D, YYYY') == item.format('MMM D, YYYY'),
            }"
          >
            {{ item.format("D") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localData from "dayjs/plugin/localeData";
import { mapState } from "vuex";
dayjs.extend(localData);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export default {
  props: ["height", "prefill", "noedit"],
  data() {
    return {
      monthRange: [],
      selected: null,
      absDate: null,
    };
  },
  methods: {
    generateCal(date) {
      this.monthRange = [];
      let day = date.startOf("month").startOf("week");
      for (let x = 0; x < 42; x++) {
        this.monthRange.push(day.add(x, "day"));
      }
    },
    increment() {
      this.date = this.date.add(1, "month");
      this.generateCal(this.date);
    },
    decrement() {
      this.date = this.date.subtract(1, "month");
      this.generateCal(this.date);
    },
    async selectDate(val) {
      this.selected = val.locale(this.lang).format("ll");
      this.absDate = val;
      this.$emit("changeDate", val);
      this.show = false;
      await this.$wait();
      let el = document.getElementsByClassName('selected')[0]
      if (el) el.focus()
    },
    init() {
      this.date = dayjs();
      this.today = dayjs().format("DD/MMM/YYYY");
      this.selected = dayjs().locale(this.lang).format("ll");
      this.absDate = dayjs();

      if (this.prefill) {
        this.selected = dayjs(this.prefill);
        this.selected = this.selected.locale(this.lang).format("ll");
        this.date = dayjs(this.prefill);
        this.absDate = dayjs(this.prefill);
      }
      this.generateCal(this.date);

      if (!this.noedit) return;
      this.$emit("changeDate", dayjs(this.absDate));
    },
  },
  computed: {
    ...mapState("user", ["lang"]),
    ...mapState("schedule", ["datetime"]),
    inputStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (!this.noedit) ob.backgroundColor = "#00000000";
      return ob;
    },
    boxStyling: function () {
      let ob = {};
      if (this.height) ob.height = this.height + "px";
      if (this.height) ob.minWidth = this.height + "px";
      if (this.show) ob.backgroundColor = "#000000";
      return ob;
    },
    daysOfWeek() {
      return dayjs().locale(this.lang).localeData().weekdaysMin();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.datePickerContainer {
  width: 100%;
}

.datepicker {
  width: 240px;
  /* height: 290px; */
  padding: 15px;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.headings {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  opacity: 0.6;
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 205px;
  margin-top: 10px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}



.oval:hover,
.oval:focus {
  background-color: #3a4b5a7a;
  border-radius: 50%;
  height: 80%;
  width: 80%;
  opacity: 1;
  outline: none;
}

.selected {
  background-color: #3a4b5a;
  border: 1px solid #409875;
  color: #56bc93;
  border-radius: 50%;
  height: 80%;
  width: 80%;
  font-weight: 400;
}

.iconFilter:focus,
.iconFilter:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
  border-radius: 50%;
  outline: none;
}

.today-oval {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

@media only screen and (max-width: 576px) {
}
</style>